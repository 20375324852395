require('./bootstrap');

import $ from 'jquery';
// import 'slick-carousel';
require('slick-carousel');
require('@fancyapps/fancybox');
require('jquery-match-height');
require('eonasdan-bootstrap-datetimepicker');
require('jquery-validation');

// $('.some-element').slick();

require('../front/js/index.js');
// require('../front/js/google-map.js');