module.exports.initNav = function()
{
	// if($('body').height() >= $(window).height())
	// {
		if($(window).scrollTop() == 0)
		{
			$('.site-header').removeClass('fixed');
			// $('.site-main').css({"padding-top": 0});
		}
		else
		{
			if (!$('.site-header').hasClass('fixed')) {
				$('.site-header').addClass('fixed');
				// $('.site-main').css({"padding-top": $('.site-header').outerHeight()});
			}
		}
	// }
}

// var height = $('.site-footer').outerHeight(true) + $('.site-header').outerHeight(true);
// $('.site-main').css({
// 	'min-height': ($(window).height() - height)
// });