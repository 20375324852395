
// import {initNav} from './favourite.js';
// import {gMap} from './default-map-script.js';

// let google = require('./default-map-script');
let navbar = require('./navbar');

/*----------  Document Ready  ----------*/

$(document).ready(function() {

	var $hamburger = $(".hamburger");
	$hamburger.on("click", function(e) {
		$hamburger.toggleClass("is-active");
	});

	$('.main-slider').slick({
		arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
		dots: true,
		lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 3000,
		prevArrow: $('.main-slider-prev'),
		nextArrow: $('.main-slider-next'),
		appendDots: '.main-slider-dots-container',
		customPaging: function() { return `<span class='slick-dots-circle'></span>`; },
	});

	$('.messageboard-carousel').slick({
		arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
		prevArrow: $('.message-prev'),
		nextArrow: $('.message-next')
	});

	$('.gallery-carousel').slick({
		dots: false,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesPerRow: 4,
		rows: 2,
		centerMode: true,
		variableWidth: true,
		prevArrow: $('.gallery-prev'),
		nextArrow: $('.gallery-next'),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesPerRow: 3,
			  }
			},
			{
				breakpoint: 640,
				settings: {
				  slidesToShow: 1,
				  slidesPerRow: 1,
				  rows: 1,
				}
			  }
		]
	});

	$(".testimonial-slide").slick({
		arrows: false,
        dots: true,
        autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		appendDots: '.slick-slider-dots-container',
		customPaging: function() { return `<span class='slick-dots-circle'></span>`; },
	});

	if($('.infrastructure-slick-slider').length){
		$('.infrastructure-slick-slider').slick({
			autoplay: true,
			arrows:false,
			dots: true,
			infinite: true,
			arrows: true,
			adaptiveHeight: true,
			prevArrow: $('.main-slider-prev'),
			nextArrow: $('.main-slider-next'),
			appendDots: '.main-slider-dots-container',
			customPaging: function() { return `<span class='slick-dots-circle'></span>`; },
		});
	}

	$(".fancybox").fancybox({
		arrows: true,
		smallBtn: "auto",
		defaultType: "image",
		slideShow: {
			autoStart: true,
			speed: 3000
		},
		buttons: [
			"zoom",
			//"share",
			"slideShow",
			//"fullScreen",
			//"download",
			"thumbs",
			"close"
		],
	});

	navbar.initNav();

	// google.gMap();

});
/*----------  Window Events  ----------*/

$(window).on('load resize', function() {

});

$( window ).scroll(function() {
	navbar.initNav();
});